import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { Car, NewCar } from '@dougs/vehicles/dto';
import { VehicleHttpService } from '../http/vehicle.http';

interface VehicleState {
  cars: Car[];
  activeCars: Car[];
  newCar: NewCar | null;
}

@Injectable({
  providedIn: 'root',
})
export class VehicleStateService extends StateService<VehicleState> {
  constructor(
    private readonly logger: LoggerService,
    private readonly vehicleHttpService: VehicleHttpService,
  ) {
    super();
  }

  readonly cars$: Observable<Car[]> = this.select((state) => state.cars);
  readonly activeCorporateCars$: Observable<Car[]> = this.select(
    (state) => state.activeCars?.filter((car) => car.type === 'corporate') ?? [],
  );
  readonly activePersonalCars$: Observable<Car[]> = this.select(
    (state) => state.activeCars?.filter((car) => car.type === 'personal') ?? [],
  );
  readonly newCar$: Observable<NewCar | null> = this.select((state) => state.newCar);

  async refreshCars(companyId: number): Promise<void> {
    try {
      this.setState({
        cars: this.getAndSetCacheState('vehicles', companyId)
          ? this.state.cars
          : await lastValueFrom(this.vehicleHttpService.getCars(companyId)),
      });
    } catch (e) {
      this.clearCache('vehicles');
      this.logger.error(e);
    }
  }

  async refreshActiveCars(companyId: number, accountingYearId: number): Promise<void> {
    try {
      this.setState({
        activeCars: await lastValueFrom(this.vehicleHttpService.getActiveCars(companyId, accountingYearId)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async deleteCar(car: Car): Promise<void> {
    try {
      await lastValueFrom(this.vehicleHttpService.deleteCar(car));

      this.setState({
        cars: this.state.cars.filter((carIterated: Car) => car.id !== carIterated.id),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async updateCar(car: Car): Promise<void> {
    try {
      const carUpdated: Car = await lastValueFrom(this.vehicleHttpService.updateCar(car));
      this.setState({
        cars: this.state.cars.map((carIterated: Car) => (car.id === carIterated.id ? carUpdated : carIterated)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async addCar(companyId: number, car: NewCar): Promise<Car | null> {
    try {
      const carAdded: Car = await lastValueFrom(this.vehicleHttpService.addCar(companyId, car));
      this.setState({
        cars: [...(this.state?.cars || []), carAdded],
        newCar: null,
      });

      return carAdded;
    } catch (e) {
      this.logger.error(e);

      return null;
    }
  }

  createCar(companyId: number, data: Record<string, string>): Observable<Record<string, string>> {
    return this.vehicleHttpService.createCar(companyId, data);
  }

  selectNewCar(car: NewCar | null): void {
    this.setState({
      newCar: car,
    });
  }

  async uploadFile(car: Car, modelFileKey: keyof Car, file: FileList): Promise<void> {
    if (file.length === 0) {
      return;
    }
    try {
      const attachment = await lastValueFrom(this.vehicleHttpService.uploadFile(car, modelFileKey, file[0]));

      this.setState({
        cars: this.state.cars.map((carIterated: Car) =>
          carIterated.id === car.id ? { ...car, [modelFileKey]: attachment } : carIterated,
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }
}
