export const RENTAL_CARS = {
  defaultText: 'Sélectionner un véhicule de location',
  items: [
    {
      label: 'Véhicule de tourisme essence',
      value: {
        name: 'location tourisme essence',
        type: 'rental',
        category: 'tourism',
        fuelType: 'petrol',
      },
    },
    {
      label: 'Véhicule de tourisme diesel',
      value: {
        name: 'location tourisme diesel',
        type: 'rental',
        category: 'tourism',
        fuelType: 'diesel',
      },
    },
    {
      label: 'Véhicule de tourisme électrique',
      value: {
        name: 'location tourisme électrique',
        type: 'rental',
        category: 'tourism',
        fuelType: 'electricity',
      },
    },
    {
      label: 'Véhicule de tourisme gas naturel',
      value: {
        name: 'location tourisme gas naturel',
        type: 'rental',
        category: 'tourism',
        fuelType: 'electricity',
      },
    },
    {
      label: 'Véhicule utilitaire essence',
      value: {
        name: 'location utilitaire essence',
        type: 'rental',
        category: 'utility',
        fuelType: 'petrol',
      },
    },
    {
      label: 'Véhicule utilitaire diesel',
      value: {
        name: 'location utilitaire diesel',
        type: 'rental',
        category: 'utility',
        fuelType: 'diesel',
      },
    },
    {
      label: 'Véhicule utilitaire électrique',
      value: {
        name: 'location utilitaire électrique',
        type: 'rental',
        category: 'utility',
        fuelType: 'electricity',
      },
    },
    {
      label: 'Véhicule utilitaire gas naturel',
      value: {
        name: 'location utilitaire gas naturel',
        type: 'rental',
        category: 'utility',
        fuelType: 'electricity',
      },
    },
  ],
};
