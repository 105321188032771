import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { Attachment } from '@dougs/core/files';
import { Car, NewCar } from '@dougs/vehicles/dto';

@Injectable({
  providedIn: 'root',
})
export class VehicleHttpService {
  constructor(private readonly http: HttpClient) {}

  getCars(companyId: number, type: string[] = ['corporate', 'personal']): Observable<Car[]> {
    const params: HttpParams = new HttpParams().appendAll({
      type,
    });

    return this.http.get<Car[]>(`${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/cars`, { params });
  }

  getActiveCars(
    companyId: number,
    accountingYearId: number,
    type: string[] = ['corporate', 'personal'],
  ): Observable<Car[]> {
    const params: HttpParams = new HttpParams().appendAll({
      type,
    });

    return this.http.get<Car[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/accounting-years/${accountingYearId}/active-cars`,
      { params },
    );
  }

  deleteCar(car: Car): Observable<Car> {
    return this.http.delete<Car>(`${AppConfig.settings.legacyApiServerUrl}/companies/${car.companyId}/cars/${car.id}`);
  }

  updateCar(car: Car): Observable<Car> {
    return this.http.post<Car>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${car.companyId}/cars/${car.id}`,
      car,
    );
  }

  addCar(companyId: number, car: NewCar): Observable<Car> {
    return this.http.post<Car>(`${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/cars`, car);
  }

  createCar(companyId: number, value: Record<string, unknown>): Observable<Record<string, string>> {
    return this.http.post<Record<string, string>>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/cars`,
      value,
    );
  }

  uploadFile(car: Car, fileType: string | undefined, file: File): Observable<Attachment> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<Attachment>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${car.companyId}/cars/${car.id}/attachments?filename=${file.name}&type=${fileType}`,
      formData,
    );
  }
}
